export const DH = {
  EVENT_TITLE: 'EVENT_TITLE',
  LOCATION: 'LOCATION',
  LOCATION_ICON: 'LOCATION_ICON',
  DESCRIPTION: 'DESCRIPTION',
  DESCRIPTION_BUTTON: 'DESCRIPTION_BUTTON',
  RESET_FILTERS_BUTTON: 'RESET_FILTERS_BUTTON',
  FILTERS_SECTION: 'FILTERS_SECTION',
  TAGS_SHOW_ALL_BUTTON: 'TAGS_SHOW_ALL_BUTTON',
  LOCATION_DROPDOWN: 'LOCATION_DROPDOWN',
  SCHEDULE_TITLE: 'SCHEDULE_TITLE',
  SCHEDULE_ITEM: 'SCHEDULE_ITEM',
  SCHEDULE_ITEM_DATETIME: 'SCHEDULE_ITEM_DATETIME',
  SCHEDULE_ITEM_DURATION: 'SCHEDULE_ITEM_DURATION',
  SCHEDULE_ITEM_NAME: 'SCHEDULE_ITEM_NAME',
  MOBILE_SCHEDULE_ITEM: 'MOBILE_SCHEDULE_ITEM',
  MOBILE_FILTER_MODAL: 'MOBILE_FILTER_MODAL',
  MOBILE_FILTER_LOCATION_RADIO: 'MOBILE_FILTER_LOCATION_RADIO',
  MOBILE_FILTER_TAG_CHECKBOX: 'MOBILE_FILTER_TAG_CHECKBOX',
  MOBILE_FILTER_SUBMIT: 'MOBILE_FILTER_SUBMIT',
  MOBILE_FILTER_RESET: 'MOBILE_FILTER_RESET',
  MOBILE_FILTER_BUTTON: 'MOBILE_FILTER_BUTTON',
  GET_TICKETS_BUTTON: 'GET_TICKETS_BUTTON',
}
