
  import { getWidgetWrapper } from '@wix/yoshi-flow-editor-runtime/build/esm/WidgetWrapper.js';
  import Widget from '/home/builduser/work/338eadb1c81287ab/wix-events-statics/events-viewer/src/components/schedule/Widget/index.tsx';
  import {
    HttpProvider,
  } from '@wix/yoshi-flow-editor';

  import {
    withStyles
  } from '@wix/native-components-infra';

  

  import { initI18nWithoutICU as initI18n } from '@wix/yoshi-flow-editor-runtime/build/esm/i18next/init';



  import stylesParams from '/home/builduser/work/338eadb1c81287ab/wix-events-statics/events-viewer/src/components/schedule/stylesParams.ts';

  var sentryConfig = {
      DSN: 'https://f1ffc0b5efe04e9eb9762cd808722520@sentry.wixpress.com/748',
      id: 'f1ffc0b5efe04e9eb9762cd808722520',
      projectName: 'events-viewer',
      teamName: 'events',
    };

  var UserComponent = getWidgetWrapper({
      initI18n,
      HttpProvider,
      withStylesHoc: withStyles,
    }, Widget, {
    sentryConfig,
    stylesParams,
    name: 'schedule',
    localeDistPath: 'assets/locales',
  });

  
    import { hot } from '@wix/component-hot-loader';
    UserComponent = hot(module, UserComponent);
  

  const loadChunks = Widget.loadChunks;

  export default {
    component: UserComponent,
    loadChunks
  };
