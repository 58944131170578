import {useSettings} from '@wix/yoshi-flow-editor/tpa-settings/react'
import React from 'react'
import {Button as UIButton, PRIORITY} from 'wix-ui-tpa/Button'
import classNames from 'classnames'
import settingsParams, {isFullButtonStyle} from '../../settingsParams'
import {DH} from '../../data-hooks'
import {NavigateToEventDetails} from '../../actions/navigate'
import {classes} from './button.st.css'

interface ButtonProps {
  eventDetailsUrl: string
  fullWidth?: boolean
  navigate: NavigateToEventDetails
  isSite: boolean
}

export const Button: React.FC<ButtonProps> = ({eventDetailsUrl, fullWidth, isSite, navigate}) => {
  const settings = useSettings()
  const showButton = settings.get(settingsParams.showButton)

  if (!showButton) {
    return null
  }

  const buttonStyle = settings.get(settingsParams.buttonStyle)
  const full = isFullButtonStyle(buttonStyle)

  return (
    <UIButton
      className={classNames(full ? classes.full : classes.hollow, {[classes.fullWidth]: fullWidth})}
      data-hook={DH.GET_TICKETS_BUTTON}
      upgrade
      as="a"
      href={isSite ? eventDetailsUrl : undefined}
      onClick={isSite ? null : () => navigate()}
      fullWidth={fullWidth}
      priority={full ? PRIORITY.primary : PRIORITY.secondary}
    >
      {settings.get(settingsParams.buttonText)}
    </UIButton>
  )
}
