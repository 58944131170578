import * as React from 'react'
import {SectionNotification as SectionNotificationTPA} from 'wix-ui-tpa/SectionNotification'
import {SectionNotificationProps} from '.'

export const SectionNotification = ({text, icon, className, size, type}: SectionNotificationProps) => (
  <SectionNotificationTPA size={size} type={type} className={className}>
    {icon ? <SectionNotificationTPA.Icon icon={icon} /> : null}
    <SectionNotificationTPA.Text>{text}</SectionNotificationTPA.Text>
  </SectionNotificationTPA>
)
