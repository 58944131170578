import {useSettings} from '@wix/yoshi-flow-editor/tpa-settings/react'
import classNames from 'classnames'
import React from 'react'
import Location from 'wix-ui-icons-common/LocationSmall'
import {useExperiments} from '@wix/yoshi-flow-editor'
import {DH} from '../../../data-hooks'
import settingsParams from '../../../settingsParams'
import {Tags} from '../../filters/tags'
import {Description} from '../description'
import {ItemDivider} from '../divider'
import {Time} from '../time'
import {
  getScheduleItemDescription,
  isScheduleItemCanceled,
  isScheduleItemWithIndication,
} from '../../../selectors/schedule'
import {Indications} from '../../indications'
import s from './desktop.scss'
import type {ItemProps} from '.'

export const DesktopItem = ({item, showDivider, readDescriptionClicked, t}: ItemProps) => {
  const {experiments} = useExperiments()
  const {formattedTimeSlot, formattedDuration, name, location, tags, id, formattedUpdatedDate} = item
  const settings = useSettings()
  const showDuration = settings.get(settingsParams.showDuration)
  const description = getScheduleItemDescription(item)
  const canceled = isScheduleItemCanceled(item)
  const scheduleUpdatedIndicationEnabled = experiments.enabled('specs.events.ui.ScheduleUpdatedIndication')

  return (
    <li>
      <Indications
        canceled={canceled}
        t={t}
        formattedUpdatedDate={formattedUpdatedDate}
        scheduleUpdatedIndicationEnabled={scheduleUpdatedIndicationEnabled}
      />
      <div
        className={classNames(s.itemContent, {
          [s.itemWithIndication]: isScheduleItemWithIndication(item, scheduleUpdatedIndicationEnabled),
        })}
        data-hook={DH.SCHEDULE_ITEM}
      >
        <div className={s.timeContainer}>
          <Time className={s.timeSlot} data-hook={DH.SCHEDULE_ITEM_DATETIME} dateTime={formattedTimeSlot}>
            {formattedTimeSlot}
          </Time>
          {showDuration && (
            <div className={s.duration} data-hook={DH.SCHEDULE_ITEM_DURATION}>
              {formattedDuration}
            </div>
          )}
        </div>
        <div className={s.content}>
          <div className={classNames(s.title, s.text)} data-hook={DH.SCHEDULE_ITEM_NAME}>
            {name}
          </div>
          {location && (
            <div className={s.location} data-hook={DH.LOCATION}>
              <div>
                <Location data-hook={DH.LOCATION_ICON} />
              </div>
              <div className={s.text}>{location}</div>
            </div>
          )}
          {tags && <Tags t={t} className={s.tags} tags={tags} />}
          {description && (
            <Description text={description} itemId={id} readDescriptionClicked={readDescriptionClicked} />
          )}
        </div>
      </div>
      {showDivider && <ItemDivider />}
    </li>
  )
}
