import * as React from 'react'
import {Text} from 'wix-ui-tpa/Text'
import {TEXT_BUTTON_PRIORITY, TextButton} from 'wix-ui-tpa/TextButton'
import {useSettings} from '@wix/yoshi-flow-editor/tpa-settings/react'
import {ItemDivider} from '../schedule/divider'
import {DH} from '../../data-hooks'
import settingsParams, {isFullButtonStyle} from '../../settingsParams'
import {classes} from './no-results.st.css'
import {NoResultsProps} from '.'

export const NoResults: React.FC<NoResultsProps> = ({onLinkClick, t}) => {
  const settings = useSettings()
  const buttonStyle = settings.get(settingsParams.buttonStyle)
  const full = isFullButtonStyle(buttonStyle)
  return (
    <div className={classes.container}>
      <ItemDivider />
      <div className={classes.innerContainer}>
        <Text className={classes.text}>{t('noResultsText')}</Text>
        <TextButton
          className={full ? classes.fullButton : classes.hollowButton}
          priority={TEXT_BUTTON_PRIORITY.primary}
          data-hook={DH.RESET_FILTERS_BUTTON}
          onClick={() => onLinkClick()}
        >
          {t('noResultsLink')}
        </TextButton>
      </div>
      <ItemDivider />
    </div>
  )
}
