import {useSettings} from '@wix/yoshi-flow-editor/tpa-settings/react'
import React, {useState} from 'react'
import {Text} from 'wix-ui-tpa/Text'
import {TEXT_BUTTON_PRIORITY, TextButton} from 'wix-ui-tpa/TextButton'
import {ChevronUp, ChevronDown} from 'wix-ui-icons-common'
import {DH} from '../../../data-hooks'
import settingsParams from '../../../settingsParams'
import {classes} from './description.st.css'
import {DescriptionProps} from '.'

export const Description: React.FC<DescriptionProps> = ({text, itemId, readDescriptionClicked}) => {
  const [opened, toggle] = useState(false)
  const settings = useSettings()
  const readMoreText = settings.get(settingsParams.readMore)
  const readLessText = settings.get(settingsParams.readLess)

  const handleReadMore = () => {
    const newOpened = !opened

    if (newOpened) {
      readDescriptionClicked({itemId})
    }

    toggle(newOpened)
  }

  return (
    <div className={classes.container}>
      <TextButton
        className={classes.button}
        priority={TEXT_BUTTON_PRIORITY.primary}
        data-hook={DH.DESCRIPTION_BUTTON}
        onClick={handleReadMore}
        suffixIcon={opened ? <ChevronUp /> : <ChevronDown />}
      >
        {opened ? readLessText : readMoreText}
      </TextButton>
      {opened && (
        <Text className={classes.text} data-hook={DH.DESCRIPTION}>
          <div dangerouslySetInnerHTML={{__html: text}} />
        </Text>
      )}
    </div>
  )
}
